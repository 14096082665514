export const environment = {
  production: true,
  baseUrl: "https://core.lafka.tools",
  urlSuffix: "/api/latest",
  xToken: "$1$7sCYP6Qz$1./BBR.GLEJYRLSoNRNPc1",
  firebase: {
    apiKey: "AIzaSyD1Irt3TsIfc5mtbEMo8s5H6-AP5UP9LEw",
    authDomain: "lafka-backoffice.firebaseapp.com",
    projectId: "lafka-backoffice",
    storageBucket: "lafka-backoffice.appspot.com",
    messagingSenderId: "629303331535",
    appId: "1:629303331535:web:6dc0e65597ce83362bc5ba",
  },
};
